// JavaScript Document

// Scripts written by Jacob Bearce @ Weblinx, Inc.

// init swiper
const swiper = new Swiper (".swiper-container", {
    autoplay: 5000,
    speed: 150,
    loop: true,
    pagination: ".swiper-pagination",
    paginationClickable: true,
    nextButton: ".swiper-button.-next",
    prevButton: ".swiper-button.-prev",
});

console.log(swiper.slides);

if ((swiper.slides.length - 2) <= 1) {
    swiper.lockSwipes();
    swiper.stopAutoplay();
}
